var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Welcome PCN Data Lake Admin Platform\n        ")]), _vm._v(" "), _c("div", [_c("Timeline", {
    attrs: {
      "timeline-items": _vm.dataTimeline,
      "message-when-no-items": _vm.messageWhenNoItems,
      uniqueTimeline: true,
      "unique-year": true,
      "show-day-and-month": true,
      order: "desc",
      dateLocale: "zh-CN"
    }
  })], 1), _vm._v(" "), _c("el-button", {
    staticClass: "load-more",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.loadMore
    }
  }, [_vm._v("Load more")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };